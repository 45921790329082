<template>
  <Form class="flex flex-col font-fs-elliot-pro py-3 md:py-7 lg:py-5" @submit="doSubmit" v-slot="{ errors }">
    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-8">
      <div class="w-full mt-2 md:mt-0 md:w-1/3 md:w-40" :class="[!isPopup && 'lg:w-1/4']">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Conclude: </label>
      </div>
      <div class="w-full md:w-2/3 mt-2 md:mt-0">
        <ConcludeEvent :initValue="conclude.type" :isEmbed="false" @onChange="conclude.type = $event" />
      </div>
    </div>
    <div class="relative border-t p-2 pt-4">
      <span class="absolute -top-8 left-2 text-md md:text-lg font-montserrat font-semibold text-magenta"> Pre-event </span>
      <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
        <div class="w-full mt-2 md:mt-0 md:w-1/3 md:w-40" :class="[!isPopup && 'lg:w-1/4']">
          <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Background color: </label>
        </div>
        <div class="md:flex md:items-center w-2/3 md:w-auto lg:w-2/3 mt-2 md:mt-0">
          <div class="relative flex items-center">
            <span class="absolute left-4 text-lg font-semibold text-grey-mid">
              # &nbsp;
              {{ currentEvent.preEventConfig.bgColor.substring(1) }}
              <!-- {{ currentEvent.preEventConfig.bgColor }} -->
            </span>

            <span class="absolute left-11.5 w-1px h-12 bg-grey-mid"></span>

            <Field
              v-model="currentEvent.preEventConfig.bgColor"
              name="preEventBackgroundColor"
              type="color"
              class="h-12 w-52 md:w-60 text-lg text-left font-semibold appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-grey-dark dark:text-dark-textPrimary border border-grey-mid focus:border-magenta focus:outline-none focus:ring-1 focus:ring-magenta rounded p-0.5 pl-11.75 pr-1"
            />
          </div>
        </div>
      </div>

      <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
        <div class="w-full mt-2 md:mt-0 md:w-1/3 md:w-40 md:pt-0" :class="[!isPopup && 'lg:w-1/4']">
          <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Background Image: </label>
        </div>
        <div class="md:flex md:items-center w-2/3 md:w-auto lg:w-2/3 mt-2 md:mt-0">
          <label
            :class="{ 'py-7': empty(eventImage.preEventBackgroundImage) }"
            class="w-60 lg:w-60 h-20 lg:h-24 flex flex-col relative items-center bg-off-white-1 dark:bg-dark-bgPrimary text-black-1 dark:text-dark-textPrimary rounded-lg border border-grey-mid focus:ring-magenta focus:border-magenta cursor-pointer md:ml-0"
          >
            <QRCodeLogo v-if="empty(eventImage.preEventBackgroundImage)" class="h-8 w-8" />
            <img v-else :src="eventImage.preEventBackgroundImage" alt="Background Image" class="h-full" />
            <input name="preEventBackgroundImage" type="file" class="hidden" v-on:change="handleChangeFilesUpload" accept="image/png, image/jpeg" />
          </label>
          <template v-if="!empty(eventImage.preEventBackgroundImage)">
            <button
              type="button"
              class="bg-transparent text-lg lg:text-xl text-magenta font-bold inline-flex items-center border-none overflow-hidden focus:outline-none p-2 lg:px-4"
              @click="handleDeleteFilesUpload('preEventBackgroundImage')"
            >
              <DeleteIcon class="w-6 h-6 mr-1 lg:mr-2" />
              <span>Delete</span>
            </button>
          </template>
        </div>
      </div>

      <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
        <div class="w-full mt-2 md:mt-0 md:w-1/3 md:w-40 md:pt-0" :class="[!isPopup && 'lg:w-1/4']">
          <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Foreground Image: </label>
        </div>
        <div class="md:flex md:items-center w-2/3 md:w-auto lg:w-2/3 mt-2 md:mt-0">
          <label
            :class="{ 'py-7': empty(eventImage.preEventForegroundImage) }"
            class="w-60 lg:w-60 h-20 lg:h-24 flex flex-col relative items-center bg-off-white-1 dark:bg-dark-bgPrimary text-black-1 dark:text-dark-textPrimary rounded-lg border border-grey-mid focus:ring-magenta focus:border-magenta cursor-pointer md:ml-0"
          >
            <QRCodeLogo v-if="empty(eventImage.preEventForegroundImage)" class="h-8 w-8" />
            <img v-else :src="eventImage.preEventForegroundImage" alt="Background Image" class="h-full" />
            <input name="preEventForegroundImage" type="file" class="hidden" v-on:change="handleChangeFilesUpload" accept="image/png, image/jpeg" />
          </label>
          <template v-if="!empty(eventImage.preEventForegroundImage)">
            <button
              type="button"
              class="bg-transparent text-lg lg:text-xl text-magenta font-bold inline-flex items-center border-none overflow-hidden focus:outline-none p-2 lg:px-4"
              @click="handleDeleteFilesUpload('preEventForegroundImage')"
            >
              <DeleteIcon class="w-6 h-6 mr-1 lg:mr-2" />
              <span>Delete</span>
            </button>
          </template>
        </div>
      </div>

      <div class="flex flex-col md:flex-row md:mb-7 lg:mb-8">
        <div class="w-1/2 md:w-1/3" :class="[!isPopup && 'lg:w-1/4']">
          <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Message: </label>
        </div>

        <div class="w-full md:w-2/3 mt-2 md:mt-0">
          <Field
            v-model="currentEvent.preEventConfig.text"
            name="preEventMessage"
            as="textarea"
            :class="{
              'text-danger border border-danger': errors['preEventMessage'],
            }"
            class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
          />
          <p class="text-danger dark:text-danger text-base italic">
            {{ errors["preEventMessage"] }}
          </p>
        </div>
      </div>
    </div>

    <div class="relative border-t p-2 pt-4 my-2">
      <span class="absolute -top-6 md:-top-8 left-2 text-md md:text-lg font-montserrat font-semibold text-magenta"> Post-event </span>
      <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
        <div class="w-full mt-2 md:mt-0 md:w-1/3 md:w-40" :class="[!isPopup && 'lg:w-1/4']">
          <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Background color: </label>
        </div>
        <div class="md:flex md:items-center w-2/3 md:w-auto lg:w-2/3 mt-2 md:mt-0">
          <div class="relative flex items-center">
            <span class="absolute left-4 text-lg font-semibold text-grey-mid">
              # &nbsp; {{ currentEvent.postEventConfig.bgColor.substring(1) }}
              <!-- {{ currentEvent.postEventBackgroundColor }} -->
            </span>

            <span class="absolute left-11.5 w-1px h-12 bg-grey-mid"></span>

            <Field
              v-model="currentEvent.postEventConfig.bgColor"
              name="postEventBackgroundColor"
              type="color"
              class="h-12 w-52 md:w-60 text-lg text-left font-semibold appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-grey-dark dark:text-dark-textPrimary border border-grey-mid focus:border-magenta focus:outline-none focus:ring-1 focus:ring-magenta rounded p-0.5 pl-11.75 pr-1"
            />
          </div>
        </div>
      </div>

      <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
        <div class="w-full mt-2 md:mt-0 md:w-1/3 md:w-40 md:pt-0" :class="[!isPopup && 'lg:w-1/4']">
          <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Background Image: </label>
        </div>
        <div class="md:flex md:items-center w-2/3 md:w-auto lg:w-2/3 mt-2 md:mt-0">
          <label
            :class="{ 'py-7': empty(eventImage.postEventBackgroundImage) }"
            class="w-60 lg:w-60 h-20 lg:h-24 flex flex-col relative items-center bg-off-white-1 dark:bg-dark-bgPrimary text-black-1 dark:text-dark-textPrimary rounded-lg border border-grey-mid focus:ring-magenta focus:border-magenta cursor-pointer md:ml-0"
          >
            <QRCodeLogo v-if="empty(eventImage.postEventBackgroundImage)" class="h-8 w-8" />
            <img v-else :src="eventImage.postEventBackgroundImage" alt="Background Image" class="h-full" />
            <input name="postEventBackgroundImage" type="file" class="hidden" v-on:change="handleChangeFilesUpload" accept="image/png, image/jpeg" />
          </label>
          <template v-if="!empty(eventImage.postEventBackgroundImage)">
            <button
              type="button"
              class="bg-transparent text-lg lg:text-xl text-magenta font-bold inline-flex items-center border-none overflow-hidden focus:outline-none p-2 lg:px-4"
              @click="handleDeleteFilesUpload('postEventBackgroundImage')"
            >
              <DeleteIcon class="w-6 h-6 mr-1 lg:mr-2" />
              <span>Delete</span>
            </button>
          </template>
        </div>
      </div>

      <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
        <div class="w-full mt-2 md:mt-0 md:w-1/3 md:w-40 md:pt-0" :class="[!isPopup && 'lg:w-1/4']">
          <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Foreground Image: </label>
        </div>
        <div class="md:flex md:items-center w-2/3 md:w-auto lg:w-2/3 mt-2 md:mt-0">
          <label
            :class="{ 'py-7': empty(eventImage.postEventForegroundImage) }"
            class="w-60 lg:w-60 h-20 lg:h-24 flex flex-col relative items-center bg-off-white-1 dark:bg-dark-bgPrimary text-black-1 dark:text-dark-textPrimary rounded-lg border border-grey-mid focus:ring-magenta focus:border-magenta cursor-pointer md:ml-0"
          >
            <QRCodeLogo v-if="empty(eventImage.postEventForegroundImage)" class="h-8 w-8" />
            <img v-else :src="eventImage.postEventForegroundImage" alt="Background Image" class="h-full" />
            <input name="postEventForegroundImage" type="file" class="hidden" v-on:change="handleChangeFilesUpload" accept="image/png, image/jpeg" />
          </label>
          <template v-if="!empty(eventImage.postEventForegroundImage)">
            <button
              type="button"
              class="bg-transparent text-lg lg:text-xl text-magenta font-bold inline-flex items-center border-none overflow-hidden focus:outline-none p-2 lg:px-4"
              @click="handleDeleteFilesUpload('postEventForegroundImage')"
            >
              <DeleteIcon class="w-6 h-6 mr-1 lg:mr-2" />
              <span>Delete</span>
            </button>
          </template>
        </div>
      </div>

      <div class="flex flex-col md:flex-row md:mb-7 lg:mb-8">
        <div class="w-1/2 md:w-1/3" :class="[!isPopup && 'lg:w-1/4']">
          <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Message: </label>
        </div>

        <div class="w-full md:w-2/3 mt-2 md:mt-0">
          <Field
            v-model="currentEvent.postEventConfig.text"
            name="postEventMessage"
            as="textarea"
            :class="{
              'text-danger border border-danger': errors['postEventMessage'],
            }"
            class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
          />
          <p class="text-danger dark:text-danger text-base italic">
            {{ errors["postEventMessage"] }}
          </p>
        </div>
      </div>
    </div>

    <button
      v-if="!isPopup"
      type="submit"
      class="button_large-Alternative absolute right-0 -bottom-2 md:-top-6 lg:-top-6 xl:-top-6 md:bottom-auto uppercase bg-magenta border rounded-full py-3 lg:py-4 lg:px-8 focus:outline-none"
    >
      Save
    </button>
  </Form>
</template>

<script>
import { ref, reactive, computed, watch } from "vue";
import { Form, Field } from "vee-validate";
import { forOwn, cloneDeep, keys, startCase, camelCase, get } from "lodash";
import axios from "axios";

import { sleep } from "@Helpers/sleep";
import { putEvent, getS3UploadKey, doUpdateStatusEvent } from "@API/event/event-repository";

import DeleteIcon from "@Assets/images/DeleteIcon";
import QRCodeLogo from "@Assets/images/QRCodeLogo";

import { empty, isFile } from "@/utils";

import ConcludeEvent from "../events-list/ConcludeEvent";

export default {
  name: "DesignTab",
  props: {
    eventDetail: {
      type: Object,
      required: true,
    },
    isPopup: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Form,
    Field,
    DeleteIcon,
    // UploadIcon,
    QRCodeLogo,
    ConcludeEvent,
  },
  emits: ["toggleLoading", "onUpdate"],
  setup(props) {
    // const currentEvent = computed({
    //   get: () => props.eventDetail,
    //   set: () => {
    //     data.currentFont = props.eventDetail.font;
    //   },
    // });
    const currentEvent = computed(() => {
      const event = props.eventDetail;
      if (empty(props.eventDetail.preEventConfig)) {
        event.preEventConfig = {
          text: "",
          bgColor: "#ffffff",
        };
      }
      if (empty(props.eventDetail.postEventConfig)) {
        event.postEventConfig = {
          text: "",
          bgColor: "#ffffff",
        };
      }
      return event;
    });

    const conclude = reactive({
      type: null,
    });

    watch(
      () => props.eventDetail,
      value => {
        conclude.type = value.isConclude ? "CLOSED" : value.isPreEvent ? "PRE" : "OPEN";
      },
      {
        deep: true,
        immediate: true,
      },
    );

    return {
      currentEvent,
      conclude,
    };
  },
  data() {
    return {
      filesUpload: {
        preEventBackgroundImage: null,
        preEventForegroundImage: null,
        postEventBackgroundImage: null,
        postEventForegroundImage: null,
      },
      eventImage: {},
      payload: {},
    };
  },
  watch: {
    eventDetail: {
      handler(value) {
        const event = cloneDeep(value);
        this.eventImage = {
          preEventBackgroundImage: get(event, "preEventConfig.bgImage"),
          preEventForegroundImage: get(event, "preEventConfig.bgForegroundImage"),
          postEventBackgroundImage: get(event, "postEventConfig.bgImage"),
          postEventForegroundImage: get(event, "postEventConfig.bgForegroundImage"),
        };
        this.payload = {
          preEventBackgroundImage: get(event, "preEventConfig.bgImage"),
          preEventForegroundImage: get(event, "preEventConfig.bgForegroundImage"),
          postEventBackgroundImage: get(event, "postEventConfig.bgImage"),
          postEventForegroundImage: get(event, "postEventConfig.bgForegroundImage"),
        };
      },
      immediate: true,
    },
  },
  methods: {
    empty,
    handleDeleteFilesUpload(property, index) {
      this.filesUpload = {
        ...this.filesUpload,
        [property]: null,
      };
      this.payload = {
        ...this.payload,
        [property]: "",
      };
      this.eventImage = {
        ...this.eventImage,
        [property]: "",
      };
    },
    handleChangeFilesUpload(e) {
      const fileSelected = e.target.files[0];
      let reader = new FileReader();
      reader.onload = event => {
        this.filesUpload[e.target.name] = fileSelected;
        this.eventImage = {
          ...this.eventImage,
          [e.target.name]: event.target.result,
        };
      };
      reader.readAsDataURL(fileSelected);
    },
    async doUpdateFile(file, property) {
      if (!file.type.includes("image/")) return;

      const s3Result = await getS3UploadKey(file.type.replace("image/", ""));

      const {
        data: { fields, url },
      } = s3Result;

      const formData = new FormData();

      Object.keys(fields).forEach(key => {
        formData.append(key, fields[key]);
      });
      // Actual file has to be appended last.
      formData.append("file", file);

      const result = await axios.post(url, formData, {});

      if (result.status === 204) {
        this.payload[property] = fields.key;
      }

      // Show Alert
      this.$store.dispatch("toggleAlert", {
        isAlert: true,
        status: result.status === 204,
        title: `[${result.status === 204 ? "Successfully" : "Failed"}] ${startCase(camelCase(property))}`,
        message: result.status === 204 ? "Upload file successfully!" : "Upload file failed!",
      });

      await sleep(1600);

      // Hide Alert
      this.$store.dispatch("toggleAlert", {
        isAlert: false,
      });
    },
    async handleUploadFiles(property) {
      await this.doUpdateFile(this.filesUpload[property], property);
    },
    async doSubmit() {
      try {
        const statusType = this.conclude.type.value || this.conclude.type;
        this.$emit("toggleLoading");
        const payload = {
          ...this.currentEvent,
          isConclude: statusType === "CLOSED",
          isPreEvent: statusType === "PRE",
        };

        forOwn(payload, (value, key) => {
          if (key.includes("Url") && (!value.url.length || value.url === " ")) delete payload[key];
        });
        const filesUploadKeys = keys(this.filesUpload);

        for (let i = 0; i < filesUploadKeys.length; i++) {
          const file = this.filesUpload[filesUploadKeys[i]];
          if (isFile(file) || !empty(file)) {
            await this.handleUploadFiles(filesUploadKeys[i]);
          }
        }

        const result = await putEvent({
          ...payload,

          preEventConfig: {
            ...this.currentEvent.preEventConfig,
            bgImage: this.payload.preEventBackgroundImage,
            bgForegroundImage: this.payload.preEventForegroundImage,
          },
          postEventConfig: {
            ...this.currentEvent.postEventConfig,
            bgImage: this.payload.postEventBackgroundImage,
            bgForegroundImage: this.payload.postEventForegroundImage,
          },
        });
        await doUpdateStatusEvent(payload.id, {
          type: statusType,
        });

        if (result.success) {
          this.$emit("onUpdate", "PREPARE");
        }

        // Show Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: true,
          status: result.success,
          title: result.success ? "Successfully!" : "Failed!",
          message: result.message,
        });
      } catch (error) {
        console.log({ error });
      } finally {
        this.$emit("toggleLoading", false);

        await sleep(5000);
        // Hide Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: false,
        });
      }
    },
  },
};
</script>
<style lang="postcss" scoped>
.image-wrapper:hover::after {
  content: "✚";
  @apply absolute text-white text-5xl;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
}
.image-wrapper:hover::before {
  content: "";
  @apply bg-magenta opacity-25 absolute h-full w-full;
}
</style>
